<template>
  <div class="editorworddetail bgc_f7">
    <div class="w_layout">
      <!-- 面包屑 -->
      <div class="bread">
        <div class="bread_content" style="margin-top: 0; padding: 20px 0">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>研修中心</el-breadcrumb-item>
            <el-breadcrumb-item>教研活动</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="font_orange">活动详情</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- <div class="bread_hengxian"></div> -->
      </div>
      <div class="content clearfix">
        <div class="left fl">
          <p class="fs20 fw600 pb22">的非官方都</p>
          <div class="fsbc pb20">
            <div class="fc">
              <div class="custom">自定义</div>
              <div class="template">教案模板</div>
            </div>
            <div class="txt">
              <span>保存</span>
              <span>存为副本</span>
              <span>导出</span>
              <span>保存</span>
              <!-- <el-button type="text" @click="onSetWord(1)"
                >设置为主文档</el-button -->
              <el-checkbox v-model="openSave">设置为主文档</el-checkbox>
              <el-checkbox v-model="openSave">开启自动保存</el-checkbox>
            </div>
          </div>
          <div class="editor">
            <el-button type="primary">点击在线编辑文档</el-button>
          </div>
        </div>
        <div class="right fr">
          <div class="fsbc mb22">
            <span class="fs16 fw600">文档记录（5）</span>
            <!-- <i class="el-icon-s-unfold" style="font-size: 20px"></i> -->
          </div>
          <ul class="uu">
            <li v-for="(e, i) in 10" :key="i">
              <div class="fc pb10">
                <p class="mr10 ellipsis" style="width:144px">符合贷款首付符合贷sdfsd</p>
                <div class="word">主文档</div>
                <div class="word editor">编辑中</div>
              </div>
              <p class="c9 fs14">
                <span>最新编辑：</span>
                <span>2021-5-5 20:20</span>
              </p>
              <div class="dian">
                <el-dropdown style="line-height: 1">
                  <span class="fs20 fw600 c9"> ... </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      @click.native="onSetWord(e.id)"
                      v-for="(e, i) in dropdownList"
                      :key="i"
                      >{{ e.name }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 设置为主文档/文档重命名 -->
    <!-- 创建在线文档弹窗 -->
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="30%"
      @close="onCloseDialog"
    >
      <el-form
        :model="wordForm"
        :rules="wordRules"
        ref="wordRef"
        label-width="100px"
      >
        <el-form-item
          label="文档名称："
          prop="name"
          v-if="title === '文档重命名'"
        >
          <el-input
            v-model="wordForm.name"
            clearable
            placeholder="请输入文档名称"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item v-else>
          <div class="fs16">是否将此文档修改成主文档？原有的主文档会被替代</div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmitForm">确定</el-button>
          <el-button @click="open = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openSave: false,
      wordForm: {
        name: "",
      },
      wordRules: {
        name: [{ required: true, message: "请输入文档名称", trigger: "blur" }],
      },
      open: false,
      title: "",
      dropdownList: [
        {
          name: "设为主文档",
          id: 1,
        },
        {
          name: "重命名",
          id: 2,
        },
        {
          name: "删除",
          id: 3,
        },
      ],
    };
  },
  methods: {
    // 关闭弹窗操作
    onCloseDialog() {
      this.open = false;
      this.$refs.wordRef.resetFields();
      this.wordForm = this.$options.data().wordForm;
    },
    // 确定
    onSubmitForm() {
      this.$refs.wordRef.validate((valid) => {
        if (valid) {
          alert("submit!");
          this.open = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 操作文档
    onSetWord(type) {
      this.open = true;
      if (type === 1) {
        // 设置为主文档
        this.title = "设置为主文档";
      } else if (type === 2) {
        // 文档重命名
        this.title = "文档重命名";
      } else {
        // 删除
      }
    },
  },
};
</script>
 
<style lang="less" scoped>
.editorworddetail {
  padding-bottom: 50px;
  .content {
    .left {
      width: 1045px;
      box-sizing: border-box;
      margin-right: 20px;
      background-color: #fff;
      border-radius: 6px;
      padding: 38px 35px 50px;
      min-height: 500px;
      .editor {
        min-height: 740px;
        border: 1px solid #ccc;
      }
      .custom {
        width: 70px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #3489fe;
        border-radius: 2px;
        color: #fff;
        margin-right: 14px;
      }
      .template {
        width: 90px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #f7f7f7;
        border-radius: 2px;
        color: #666;
      }
      /deep/ .txt {
        color: #666;
        > span {
          margin-right: 40px;
          cursor: pointer;
        }
        .el-checkbox {
          .el-checkbox__label {
            font-size: 16px;
          }
        }
      }
    }
    .right {
      width: 335px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 6px;
      padding: 30px 4px 30px 30px;
      .uu {
        max-height: 600px;
        overflow-y: auto;
        &::-webkit-scrollbar{
            width: 5px;
        }
        li {
          border-bottom: 1px dashed #ececec;
          padding-bottom: 10px;
          margin-bottom: 20px;
          margin-right: 20px;
          &:last-child {
            margin-bottom: 0;
            border-bottom: none;
          }
          .word {
            width: 48px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            border: 1px solid #ff8201;
            border-radius: 2px;
            color: #ff8201;
            font-size: 12px;
            margin-right: 6px;
          }
          .editor {
            border: 1px solid #3489fe;
            color: #3489fe;
          }
          .dian {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}
</style>